import React from 'react';

import './Loader.css';

function Loader() {
   return (
      <>
         <div className="progress-indicator" id="my-loader">
            <div className="progress-indicator-head">
               <div className="first-indicator"></div>
               <div className="second-indicator"></div>
            </div>
            <div className="insp-logo-frame">
               <svg className="insp-logo-frame-img" xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" viewBox="0 0 859.11298 632.67948">
                  <path
                     id="bf948e58-f613-467a-9468-983dc892a127-72"
                     data-name="Path 449"
                     d="M967.94152,763.86573c-10.593-.44-20.244-4.715-24.61-11.338q-.30606-.465-.577-.943c-1.594-2.822-2.092-6.066-.349-8.847s6.1-4.893,10.468-4.454,7.785,3.926,5.976,6.686c-.4-5.578-.735-11.439,3.762-16.294a16.43578,16.43578,0,0,1,9.207-4.758c9.062-1.594,15.07794,3.53,16.283,8.7.889,3.819-.339,7.7-1.562,11.479,3.769-4.457,12.73205-6.44,19.6-4.335s10.673,8,8.328,12.9c-2.76294,5.764-11.974,8.743-20.53,10.318C986.87048,764.28071,976.27649,764.21174,967.94152,763.86573Z"
                     transform="translate(-170.44351 -133.66026)"
                     fill="#f2f2f2"
                  />
                  <path
                     id="b42aec13-b3c1-4211-a06e-06d70154504c-73"
                     data-name="Path 538"
                     d="M232.95245,763.86573c10.593-.44,20.244-4.715,24.609-11.338q.30706-.465.577-.943c1.594-2.822,2.092-6.066.349-8.847s-6.1-4.893-10.468-4.454-7.786,3.926-5.976,6.686c.4-5.578.735-11.439-3.762-16.294a16.43611,16.43611,0,0,0-9.20606-4.758c-9.062-1.594-15.078,3.53-16.283,8.7-.889,3.819.339,7.7,1.562,11.479-3.769-4.457-12.732-6.44-19.6-4.335s-10.674,8-8.328,12.9c2.763,5.764,11.974,8.743,20.53,10.318C214.0235,764.28071,224.61652,764.21174,232.95245,763.86573Z"
                     transform="translate(-170.44351 -133.66026)"
                     fill="#f2f2f2"
                  />
                  <rect id="ff921942-c1a5-4092-8e51-cfff0188952a" data-name="Rectangle 73" y="629.23648" width="859.11298" height="3.443" fill="#ccc" />
                  <path
                     id="a8ec5313-8437-40fa-9ae6-49ad9c080ae2-74"
                     data-name="Path 525"
                     d="M516.84549,241.87172a13.191,13.191,0,0,0,.062,2.077l-47.612,39.958-14.2-5.141-10.559,20.414,31.043,14.776,52.052-58.411a13.15491,13.15491,0,1,0-10.787-13.674Z"
                     transform="translate(-170.44351 -133.66026)"
                     fill="#a0616a"
                  />
                  <path
                     id="b5ad9c77-52a4-4655-bf33-f0307720c346-75"
                     data-name="Path 526"
                     d="M452.98851,308.97271l-45.978-16.6a16.92047,16.92047,0,1,1,11.489-31.831l45.978,16.6a5.639,5.639,0,0,1,3.39029,7.21821l-.00027.00079-7.659,21.221a5.648,5.648,0,0,1-7.22,3.39Z"
                     transform="translate(-170.44351 -133.66026)"
                     fill="#ccc"
                  />
                  <path
                     id="ee570c5a-79aa-496e-9580-2e913ce96aae-76"
                     data-name="Path 527"
                     d="M419.31551,470.64772h15.366l7.31-59.27h-22.678Z"
                     transform="translate(-170.44351 -133.66026)"
                     fill="#a0616a"
                  />
                  <path
                     id="ab71c019-c25e-46eb-a51f-1c429aa2c12d-77"
                     data-name="Path 528"
                     d="M464.31451,484.91673l-48.293.00253-.001-18.659,29.634-.00155a18.659,18.659,0,0,1,18.66,18.658Z"
                     transform="translate(-170.44351 -133.66026)"
                     fill="#2f2e41"
                  />
                  <path
                     id="f36d9583-efe1-4c6b-b146-57ada20cd73c-78"
                     data-name="Path 529"
                     d="M381.33951,450.79673l12.57,8.838,40.071-44.279-18.551-13.043Z"
                     transform="translate(-170.44351 -133.66026)"
                     fill="#a0616a"
                  />
                  <path
                     id="efce1b5c-13fd-487e-9a00-7bbfe0293cc7-79"
                     data-name="Path 530"
                     d="M408.4295,485.32072l-39.50449-27.769,10.72893-15.26306,24.24143,17.04012a18.65666,18.65666,0,0,1,4.53413,25.992Z"
                     transform="translate(-170.44351 -133.66026)"
                     fill="#2f2e41"
                  />
                  <path
                     id="b962c087-78c3-438a-b2f2-2ecccfec7131-80"
                     data-name="Path 531"
                     d="M452.93851,363.88172s38.347-4.524,31.464,20.359-83.577,46.374-83.577,46.374Z"
                     transform="translate(-170.44351 -133.66026)"
                     fill="#a0616a"
                  />
                  <circle id="b4ce5f2d-2188-4402-bc9c-1bcfffccb703" data-name="Ellipse 71" cx="256.10099" cy="72.46645" r="30.784" fill="#a0616a" />
                  <path
                     id="bec54b22-7d61-4f61-9a6e-d6909ce0cfb7-81"
                     data-name="Path 532"
                     d="M328.37152,425.51972c-17.517-20.4-23.646-51.7,4.288-77.736a92.07115,92.07115,0,0,1,23.168-15.386l22.512-62.963a34.309,34.309,0,0,1,31.709-22.808,32.834,32.834,0,0,1,31.851,20.484,28.50008,28.50008,0,0,1,.884,20.184,324.21164,324.21164,0,0,1-26.269,60.1l1.164,9.313,56.907,5.058-.531.863c-.277.45-28.128,42.885-63.758,71.052-12.183,9.63-24.78,13.622-36.67,13.621C355.59753,447.3057,339.19952,438.12672,328.37152,425.51972Z"
                     transform="translate(-170.44351 -133.66026)"
                     fill="#ccc"
                  />
                  <path
                     id="f5e0fb0e-7954-451a-aeef-f3006d7ce30b-82"
                     data-name="Path 533"
                     d="M323.0015,215.78571c5.446-6.49,7.782-14.978,9.662-23.24,2.973-13.071,5.274-26.587,11.88-38.252s18.745-21.33,32.13-20.594c13.309.732,24.068,11.439,29.779,23.621a10.594,10.594,0,0,1,8.6.415c4.116,2.254,5.655,8.661,2.229,11.869a28.44,28.44,0,0,1,23.159-.577,27.994,27.994,0,0,1,16.957,23.757,15.262,15.262,0,0,1-4.058,11.45c-5.906,6.08-14.26,3.983-21.688,2.434-7.016-1.463-15.561-1.858-19.514,4.827a12.52988,12.52988,0,0,0-1.219,9.156c1.514,6.673,6.455,11.321,11.248,15.77l.784,1.456c-16.924,3.3-35.4-8.258-39.844-24.919s5.84-35.882,22.162-41.44h0a6.1,6.1,0,0,1-5.48-9.145,29.01483,29.01483,0,0,0-14.805-3.699c-8.431.553-15.727,6.554-20.115,13.775s-6.375,15.6-8.387,23.807-4.19,16.569-8.882,23.6a37.55,37.55,0,0,1-60.161,3.086A29.967,29.967,0,0,0,323.0015,215.78571Z"
                     transform="translate(-170.44351 -133.66026)"
                     fill="#2f2e41"
                  />
                  <path
                     d="M560.47265,299.52659A38.80656,38.80656,0,1,1,599.27921,260.72,38.851,38.851,0,0,1,560.47265,299.52659Zm0-71.14535A32.3388,32.3388,0,1,0,592.81145,260.72,32.37533,32.37533,0,0,0,560.47265,228.38124Z"
                     transform="translate(-170.44351 -133.66026)"
                     fill="#6c63ff"
                  />
                  <path
                     d="M559.19916,212.21184a3.234,3.234,0,0,1-3.23388-3.23388V171.78835a3.23388,3.23388,0,0,1,6.46776,0V208.978A3.234,3.234,0,0,1,559.19916,212.21184Z"
                     transform="translate(-170.44351 -133.66026)"
                     fill="#6c63ff"
                  />
                  <path
                     d="M508.73058,262.6812H471.541a3.23388,3.23388,0,0,1,0-6.46776h37.18961a3.23388,3.23388,0,0,1,0,6.46776Z"
                     transform="translate(-170.44351 -133.66026)"
                     fill="#6c63ff"
                  />
                  <path
                     d="M644.55352,262.6812H607.36391a3.23388,3.23388,0,0,1,0-6.46776h37.18961a3.23388,3.23388,0,0,1,0,6.46776Z"
                     transform="translate(-170.44351 -133.66026)"
                     fill="#6c63ff"
                  />
                  <path
                     d="M606.34227,227.3217a3.23388,3.23388,0,0,1-2.25172-5.55507l15.5741-15.10986a3.23391,3.23391,0,1,1,4.50343,4.64238L608.594,226.409A3.22175,3.22175,0,0,1,606.34227,227.3217Z"
                     transform="translate(-170.44351 -133.66026)"
                     fill="#6c63ff"
                  />
                  <path
                     d="M511.36916,227.3217a3.22311,3.22311,0,0,1-2.25171-.91269l-15.5741-15.10986a3.23391,3.23391,0,1,1,4.50343-4.64238l15.5741,15.10986a3.23388,3.23388,0,0,1-2.25172,5.55507Z"
                     transform="translate(-170.44351 -133.66026)"
                     fill="#6c63ff"
                  />
                  <path
                     d="M499.02894,315.696a3.23469,3.23469,0,0,1-2.3204-5.48718l15.5741-16.03834a3.23444,3.23444,0,0,1,4.6408,4.50659L501.34935,314.7154A3.22921,3.22921,0,0,1,499.02894,315.696Z"
                     transform="translate(-170.44351 -133.66026)"
                     fill="#6c63ff"
                  />
                  <path
                     d="M625.15024,318.92986a3.21777,3.21777,0,0,1-2.31408-.97584l-18.808-19.27222a3.23322,3.23322,0,1,1,4.62817-4.51606l18.808,19.27221a3.23307,3.23307,0,0,1-2.31409,5.49191Z"
                     transform="translate(-170.44351 -133.66026)"
                     fill="#6c63ff"
                  />
                  <path
                     d="M559.19916,351.26866a3.234,3.234,0,0,1-3.23388-3.23388V310.84517a3.23388,3.23388,0,1,1,6.46776,0v37.18961A3.234,3.234,0,0,1,559.19916,351.26866Z"
                     transform="translate(-170.44351 -133.66026)"
                     fill="#6c63ff"
                  />
                  <path
                     id="b0998da5-27dc-40b2-8d45-277f647e44e1-83"
                     data-name="Path 536"
                     d="M518.38852,284.86272a13.18936,13.18936,0,0,0-.826,1.906l-60.083,15.933-10.667-10.689-18.235,13.989,21.819,26.57,71.943-30.748a13.15489,13.15489,0,1,0-3.952-16.962Z"
                     transform="translate(-170.44351 -133.66026)"
                     fill="#a0616a"
                  />
                  <path
                     id="a2d8cb05-8bad-48ca-a39c-34dd34963d12-84"
                     data-name="Path 537"
                     d="M432.06451,318.45872l-34.563-34.568a16.92036,16.92036,0,1,1,23.929-23.929h0l34.565,34.565a5.639,5.639,0,0,1,.00126,7.97475l-.00126.00125-15.953,15.954a5.648,5.648,0,0,1-7.977,0Z"
                     transform="translate(-170.44351 -133.66026)"
                     fill="#ccc"
                  />
                  <path
                     d="M798.37041,765.81491H440.28765c-77.25159,0-140.10022-63.90625-140.10022-142.45752s62.84863-142.45752,140.10022-142.45752H465.7625a173.7143,173.7143,0,0,1,62.43237-69.88818,169.2712,169.2712,0,0,1,91.13428-26.65918c89.59277,0,164.2688,70.45215,171.21875,160.9126h7.82251c59.8584,0,108.55688,49.46923,108.55688,110.2749S858.22881,765.81491,798.37041,765.81491Zm-358.08276-278.915c-73.94312,0-134.10022,61.21436-134.10022,136.45752,0,75.24268,60.1571,136.45752,134.10022,136.45752H798.37041c56.55005,0,102.55688-46.77734,102.55688-104.2749s-46.00683-104.2749-102.55688-104.2749H784.9251l-.17188-2.81739c-5.40576-88.65136-78.0686-158.09521-165.42407-158.09521-62.90381,0-121.38306,37.22851-148.98291,94.84326l-.81616,1.7041Z"
                     transform="translate(-170.44351 -133.66026)"
                     fill="#3f3d56"
                  />
               </svg>
               <h2 id="quote">Prepare for whatever Mother Nature has in store</h2>
            </div>
         </div>
      </>
   );
}

export default Loader;
