import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';

import App from './App';
import { WeatherProvider } from './contexts/WeatherContext';

import './fonts.css';
import './index.css';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
   <React.StrictMode>
      <Router>
         <WeatherProvider>
            <App />
         </WeatherProvider>
      </Router>
   </React.StrictMode>
);

